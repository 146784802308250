<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="delinquents"
      :page.sync="page"
      :items-per-page="delinquentsPerPage"
      :server-items-length="totalDelinquents"
      hide-default-footer
      @page-count="pageCount = $event"
      :options.sync="options"
      class="elevation-1 ml-4"
      :loading="loading"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Delinquents</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn
                color="green"
                dark
                disabled
                class="mb-2"
                v-on="on"
                @click="resetEdit"
                v-if="showCreate"
                >New delinquent</v-btn
              >
            </template>
            <v-card>
              <validation-observer ref="form">
                <v-form
                  ref="form1"
                  :model="formdata"
                  @submit.prevent="onSubmit"
                >
                  <v-row>
                    <v-col cols="11">
                      <v-card-title>
                        <span class="headline" v-if="view">{{
                          formTitle
                        }}</span>
                        <span class="headline" v-else>View Delinquent</span>
                      </v-card-title>
                    </v-col>
                    <v-col cols="1">
                      <v-spacer />
                      <v-btn
                        class="ml-0"
                        dark
                        icon
                        color="pink"
                        large
                        @click="close"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="2">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="nida"
                          >
                            <v-select
                              dense
                              v-model="selectedNida"
                              :items="nida"
                              label="Select"
                              item-text="name"
                              item-value="name"
                              v-if="(!edit && view && hasId) || (edit && view)"
                              outlined
                              @change="resetPassportAndNid"
                              name="nida"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="digits:16"
                            name="NID"
                          >
                            <v-text-field
                              v-if="selectedNida === 'ID' && view"
                              dense
                              label="Enter ID"
                              type="text"
                              outlined
                              counter
                              v-model="editedDelinquent.nid"
                              name="NID"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                          <v-text-field
                            v-if="selectedNida === 'PASSPORT' && view"
                            dense
                            label="Enter Passport"
                            type="text"
                            outlined
                            counter
                            v-model="editedDelinquent.passport"
                            color="grey"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-btn
                            color="green darken-1"
                            class="mt-2"
                            link
                            small
                            v-if="
                              selectedNida === 'ID' ||
                                selectedNida === 'PASSPORT'
                            "
                            @click="loadDelinquentIdentification"
                            >Search</v-btn
                          >
                          <v-spacer></v-spacer>
                          <v-btn rounded disabled right absolute>{{
                            currentCenter.name
                          }}</v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4" align="center">
                          <img
                            :src="nidaPicture"
                            v-if="!edit || !view"
                            width="200"
                            class="mb-9"
                            height="auto"
                          />
                          <img
                            :src="webcamPicture"
                            v-if="!edit || !view"
                            width="300"
                            height="auto"
                          />
                          <v-select
                            :items="devices"
                            item-text="label"
                            v-model="camera"
                            item-key="deviceId"
                            item-value="deviceId"
                            label="Select webcam"
                            outlined
                            dense
                            v-if="edit && view"
                            color="grey"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="3" v-if="edit && view">
                          <p>Selected Camera</p>
                          <vue-web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            width="200"
                            height="200"
                            class="mt-n6 picturecapture"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                          />
                          <v-card-actions>
                            <v-btn
                              color="blue"
                              small
                              link
                              left
                              class="mt-n8 picturecapture"
                              outlined
                              @click="onCapture"
                              >Capture</v-btn
                            >
                            <!-- <v-btn color="blue" dense link text outlined @click="onStop" class="mx-2">Stop</v-btn>
                                    <v-btn color="blue" dense link text outlined @click="onStart">Start</v-btn> -->
                          </v-card-actions>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <p v-if="edit && view">Captured/Retrieved Image</p>
                          <img
                            :src="webcamPicture"
                            width="200"
                            v-if="edit && view"
                            height="auto"
                          />
                          <img
                            :src="nidaPicture"
                            width="200"
                            v-if="edit && view"
                            class="mt-n12"
                            height="auto"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="recividist">
                        <v-col cols="12" sm="12" style="background: #a3414e">
                          <v-card-title
                            ><h2 style="color: white">
                              Recividist
                            </h2></v-card-title
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-card class="mt-2" max-width="1200" outlined raised>
                          <v-card-text>
                            <v-row>
                              <v-col cols="10" sm="10">
                                <p>Personal Identification</p>
                              </v-col>
                              <!-- <v-spacer />
                              <v-col cols="2" sm="2" v-if="!view">
                                <v-chip
                                  class="ma-2"
                                  color="green"
                                  text-color="white"
                                >
                                  {{ editedDelinquent.latestTransfer.state }}
                                </v-chip>
                              </v-col> -->
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="NID"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.nid"
                                  name="NID"
                                  color="grey"
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|min:5|max:15"
                                  name="First Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Passport"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.passport"
                                    name="Passport"
                                    color="grey"
                                    :error-messages="errors"
                                    :disabled="edit || !view"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Transfer State"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestTransfer.state
                                  "
                                  color="grey"
                                  disabled
                                  filled
                                  v-if="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|min:2|max:50"
                                  name="First Name"
                                >
                                  <v-text-field
                                    dense
                                    label="First Name"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.firstName"
                                    name="First Name"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|min:2|max:50"
                                  name="Last Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Last Name"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.lastName"
                                    name="Last Name"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Gender"
                                >
                                  <v-select
                                    dense
                                    :items="gender"
                                    label="Gender"
                                    item-text="name"
                                    outlined
                                    v-model="editedDelinquent.gender"
                                    return-object
                                    class="mt-n6"
                                    name="Gender"
                                    :error-messages="errors"
                                    color="grey"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Marital Status"
                                >
                                  <v-select
                                    dense
                                    :items="maritalStatus"
                                    label="Marital Status"
                                    item-text="name"
                                    outlined
                                    v-model="editedDelinquent.maritalStatus"
                                    class="mt-n6"
                                    name="Marital Status"
                                    :error-messages="errors"
                                    color="grey"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|min:2|max:50"
                                  name="Last Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Father Name"
                                    v-model="editedDelinquent.fatherName"
                                    type="text"
                                    outlined
                                    name="Father Name"
                                    color="grey"
                                    class="mt-n6"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    :error-messages="errors"
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|min:2|max:50"
                                  name="Last Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Mother Name"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.motherName"
                                    name="Mother Name"
                                    color="grey"
                                    class="mt-n6"
                                    :disabled="
                                      editedDelinquent.nid !== null ||
                                        selectedNida === null ||
                                        !view
                                    "
                                    :error-messages="errors"
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <p class="mt-n6 font-weight-thin">
                                  Place and Date of Birth
                                </p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.province.id
                                  "
                                  @change="loadBirthDistricts"
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  class="mt-n6"
                                  :disabled="
                                    editedDelinquent.nid !== null ||
                                      selectedNida === null ||
                                      !view
                                  "
                                  filled
                                />
                                <!-- <v-btn color="blue" link x-small class="mt-n12" @click="loadBirthProvinces">Load Provinces</v-btn> -->
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.district.id
                                  "
                                  @change="loadBirthSectors"
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  class="mt-n6"
                                  :disabled="
                                    editedDelinquent.nid !== null ||
                                      selectedNida === null ||
                                      !view
                                  "
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.sector.id
                                  "
                                  @change="loadBirthCells"
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  class="mt-n6"
                                  :disabled="
                                    editedDelinquent.nid !== null ||
                                      selectedNida === null ||
                                      !view
                                  "
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.cell.id
                                  "
                                  @change="loadBirthVillages"
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  class="mt-n6"
                                  :disabled="
                                    editedDelinquent.nid !== null ||
                                      selectedNida === null ||
                                      !view
                                  "
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  class="mt-n6"
                                  :disabled="
                                    editedDelinquent.nid !== null ||
                                      selectedNida === null ||
                                      !view
                                  "
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-menu
                                  ref="dateOfBirthMenu"
                                  v-model="dateOfBirthMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="formattedDateOfBirth"
                                      label="Date of Birth"
                                      outlined
                                      filled
                                      :disabled="
                                        editedDelinquent.nid !== null ||
                                          selectedNida === null ||
                                          !view
                                      "
                                      color="grey"
                                      class="mt-n6"
                                      dense
                                      readonly
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    ref="picker"
                                    v-model="dateOfBirth"
                                    :max="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    min="1900-01-01"
                                    @change="saveDateOfBirth"
                                    no-title
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card max-width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>Place of Residence</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.province.id
                                  "
                                  @change="loadResidenceDistricts"
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  required
                                  :disabled="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.district.id
                                  "
                                  @change="loadResidenceSectors"
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  required
                                  :disabled="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.sector.id
                                  "
                                  @change="loadResidenceCells"
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  required
                                  :disabled="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.cell.id
                                  "
                                  @change="loadResidenceVillages"
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  :disabled="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  :disabled="!view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4"> </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Next of kin Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Next of kin Name"
                                    type="text"
                                    v-model="editedDelinquent.guardian"
                                    outlined
                                    name="Next of kin Name"
                                    color="grey"
                                    :error-messages="errors"
                                    class="mt-n6"
                                    filled
                                    :disabled="!view"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|digits:10"
                                  name="Next of kin Phone"
                                >
                                  <v-text-field
                                    dense
                                    label="Next of kin Phone"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.guardianPhone"
                                    name="Next of kin Phone"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="!view"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="email"
                                  name="Email"
                                >
                                  <v-text-field
                                    dense
                                    label="Next of kin Email"
                                    type="text"
                                    outlined
                                    v-model="editedDelinquent.guardianEmail"
                                    name="Email"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="!view"
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card max-width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>Apprehension Details</p>
                                <p class="font-weight-thin">
                                  Place and Date of Apprehension
                                </p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.province.id
                                  "
                                  @change="loadApprehensionDistricts"
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  name="Apprehension Province"
                                  class="mt-n6"
                                  filled
                                  required
                                  :disabled="(!isUserFromTc && !edit) || !view"
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehension District"
                                >
                                  <v-select
                                    :items="apprehensionDistricts"
                                    item-text="name"
                                    item-value="id"
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehensionPlace.district.id
                                    "
                                    @change="loadApprehensionSectors"
                                    dense
                                    color="grey"
                                    label="District"
                                    outlined
                                    name="Apprehension District"
                                    :error-messages="errors"
                                    class="mt-n6"
                                    filled
                                    :rules="rulesRequired"
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehension Sector"
                                >
                                  <v-select
                                    :items="apprehensionSectors"
                                    item-text="name"
                                    item-value="id"
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehensionPlace.sector.id
                                    "
                                    @change="loadApprehensionCells"
                                    dense
                                    color="grey"
                                    label="Sector"
                                    outlined
                                    name="Apprehension Sector"
                                    :error-messages="errors"
                                    class="mt-n6"
                                    filled
                                    :rules="rulesRequired"
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehension Cell"
                                >
                                  <v-select
                                    :items="apprehensionCells"
                                    item-text="name"
                                    item-value="id"
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehensionPlace.cell.id
                                    "
                                    @change="loadApprehensionVillages"
                                    dense
                                    color="grey"
                                    label="Cell"
                                    outlined
                                    name="Apprehension Cell"
                                    :error-messages="errors"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehension Village"
                                >
                                  <v-select
                                    :items="apprehensionVillages"
                                    item-text="name"
                                    item-value="id"
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehensionPlace.village.id
                                    "
                                    dense
                                    color="grey"
                                    label="Village"
                                    outlined
                                    name="Apprehension Village"
                                    :error-messages="errors"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-menu
                                  ref="dateOfApprehensionMenu"
                                  v-model="dateOfApprehensionMenu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      rules="required"
                                      name="Apprehension Date"
                                    >
                                      <v-text-field
                                        v-model="formattedDateOfApprehension"
                                        label="Date of Apprehension"
                                        outlined
                                        filled
                                        name="Apprehension Date"
                                        :error-messages="errors"
                                        color="grey"
                                        class="mt-n6"
                                        dense
                                        readonly
                                        @click="clearDate"
                                        v-on="on"
                                        :disabled="
                                          (!isUserFromTc && !edit) || !view
                                        "
                                      ></v-text-field>
                                    </validation-provider>
                                  </template>
                                  <v-date-picker
                                    ref="picker"
                                    v-model="dateOfApprehension"
                                    :max="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    min="1900-01-01"
                                    @change="saveDateOfApprehension"
                                    no-title
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehender Name"
                                >
                                  <v-text-field
                                    dense
                                    label="Apprehender Name"
                                    type="text"
                                    outlined
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehenderName
                                    "
                                    name="Apprehender Name"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Apprehender Function"
                                >
                                  <v-text-field
                                    dense
                                    label="Apprehender Function"
                                    type="text"
                                    outlined
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehenderFunction
                                    "
                                    name="Apprehender Function"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required|digits:10"
                                  name="Phone"
                                >
                                  <v-text-field
                                    dense
                                    label="Apprehender Phone"
                                    outlined
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehenderPhone
                                    "
                                    name="Phone"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="How apprehended"
                                >
                                  <v-text-field
                                    dense
                                    label="How he/she was apprehended and act he/she was doing"
                                    type="text"
                                    outlined
                                    v-model="
                                      editedDelinquent.apprehension
                                        .apprehensionWay
                                    "
                                    name="How apprehended"
                                    :error-messages="errors"
                                    color="grey"
                                    class="mt-n6"
                                    filled
                                    :disabled="
                                      (!isUserFromTc && !edit) || !view
                                    "
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <p class="font-weight-thin">Deviant Acts</p>
                                <multiselect
                                  v-model="
                                    editedDelinquent.apprehension.deviantActs
                                  "
                                  tag-placeholder="Add"
                                  placeholder="Select"
                                  :options="deviantActs"
                                  :multiple="true"
                                  :taggable="true"
                                  :hideSelected="true"
                                  @tag="addNewDeviantAct"
                                  :disabled="(!isUserFromTc && !edit) || !view"
                                ></multiselect>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <p class="font-weight-thin">
                                  Apprehension Items
                                </p>
                                <multiselect
                                  v-model="editedDelinquent.apprehension.items"
                                  tag-placeholder="Add"
                                  placeholder="Select"
                                  :options="apprehensionItems"
                                  :multiple="true"
                                  :taggable="true"
                                  :hideSelected="true"
                                  @tag="addNewApprehensionItem"
                                  :disabled="(!isUserFromTc && !edit) || !view"
                                ></multiselect>
                                <!-- <v-btn color="blue" link x-small class="mt-1" @click="loadApprehensionItems">Load Items</v-btn> -->
                              </v-col>
                              <v-col cols="12" sm="4">
                                <p class="font-weight-thin">
                                  Identification Documents
                                </p>
                                <multiselect
                                  v-model="
                                    editedDelinquent.apprehension.documentTypes
                                  "
                                  tag-placeholder="Add"
                                  placeholder="Select"
                                  :options="identificationDocuments"
                                  :multiple="true"
                                  :taggable="true"
                                  :hideSelected="true"
                                  @tag="addNewIdentificationDocument"
                                  :disabled="(!isUserFromTc && !edit) || !view"
                                ></multiselect>
                                <!-- <v-btn color="blue" link x-small class="mt-1" @click="loadDocumentTypes">Load Documents</v-btn> -->
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <p
                          class="font-weight-thin font-italic mt-4"
                          v-if="view && edit"
                        >
                          Registered on:
                          <span>{{
                            new Date() | moment("dddd, MMMM Do YYYY")
                          }}</span>
                        </p>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      class="success ml-4 mb-4"
                      :disabled="disableSave"
                      type="submit"
                      v-if="view"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.delinquent.lastName`]="{ item }">
        {{ item.delinquent.lastName.toUpperCase() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewDelinquent(item)">
          mdi-eye
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editDelinquent(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="delinquentDialog(item)" v-if="showDelete">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this delinquent ?</p>
          <v-btn link small color="blue" @click="deleteDelinquent()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-spacer />
        <v-col cols="2"> Total: {{ totalDelinquents }} </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
import Multiselect from 'vue-multiselect'
import Location from '@/models/Location'
import Address from '@/models/Address'
import Apprehension from '@/models/Apprehension'
export default {
  data () {
    return {
      rules: [
        value =>
          value ||
          value.length > 2 ||
          value.length < 50 ||
          '2-50 maximum characters'
      ],
      rulesRequired: [
        value => (this.view && value === 0 ? '' : value || 'required')
      ],
      recividist: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      dialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Last Name', value: 'delinquent.lastName', align: 'start' },
        {
          text: 'First Name',
          value: 'delinquent.firstName'
        },
        {
          text: 'Deviant Acts',
          value: 'apprehension.deviantActs',
          sortable: false
        },
        {
          text: 'Apprehension District',
          value: 'apprehension.apprehensionPlace.district.name',
          sortable: false
        },
        {
          text: 'Apprehension Sector',
          value: 'apprehension.apprehensionPlace.sector.name',
          sortable: false
        },
        {
          text: 'Apprehension Date',
          value: 'apprehension.apprehensionDate',
          sortable: false
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      selectDelinquentsPerPage: [20, 50, 100],
      totalPages: 0,
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      centers: [],
      positions: [],
      nida: ['ID', 'PASSPORT', 'NO ID/PASSPORT'],
      gender: ['MALE', 'FEMALE'],
      maritalStatus: ['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED'],
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      selectedNida: null,
      photo: null,
      showPassword: false,
      showConfirmPassword: false,
      editedIndex: -1,
      editedDelinquent: new Delinquent(),
      defaultItem: new Delinquent(),
      webcamPicture: null,
      nidaPicture: null,
      camera: null,
      deviceId: null,
      devices: [],
      dateOfBirth: null,
      retrievedDateOfBirth: null,
      retrievedApprehensionDate: null,
      dateOfBirthMenu: false,
      dateOfApprehension: null,
      dateOfApprehensionMenu: false,
      errors: null,
      edit: true,
      view: true,
      disableSave: false,
      options: {},
      formdata: {},
      sortBy: 'apprehension.apprehensionDate,desc',
      loading: true,
      hasId: false
    }
  },
  components: {
    'vue-web-cam': WebCam,
    Multiselect
  },

  computed: {
    isUserFromTc () {
      return this.currentCenter.type === 'TRANSIT'
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Add Delinquent' : 'Edit Delinquent'
    },
    device () {
      return this.devices.find(n => n.deviceId === this.deviceId)
    },
    formattedDateOfBirth () {
      return this.dateOfBirth
        ? this.$moment(this.dateOfBirth).format('DD-MMM-YYYY')
        : this.retrievedDateOfBirth
          ? this.$moment(this.retrievedDateOfBirth).format('DD-MMM-YYYY')
          : ''
    },
    formattedDateOfApprehension () {
      return this.dateOfApprehension
        ? this.$moment(this.dateOfApprehension).format('DD-MMM-YYYY')
        : this.retrievedApprehensionDate
          ? this.$moment(this.retrievedApprehensionDate).format('DD-MM-YYYY')
          : ''
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'storeProvinces',
      'storeProvincesError',
      'deviantActs',
      'identificationDocuments',
      'apprehensionItems',
      'delinquents'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    camera (id) {
      this.deviceId = id
    },
    devices () {
      // Once we have a list select the first one
      const [first] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    },
    page: {
      handler () {
        this.loadAlldelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAlldelinquents()
      }
    },
    options: {
      handler () {
        this.loadAlldelinquents()
      },
      deep: true
    }
  },
  mounted () {
    this.loadAlldelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
    this.loadBirthProvinces()
  },
  created () {
    if (this.deviantActs.length === 0) this.loadDeviantActs()
    if (this.apprehensionItems.length === 0) this.loadApprehensionItems()
    if (this.identificationDocuments.length === 0) this.loadDocumentTypes()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', [
      'loadStoreProvinces',
      'loadDocumentTypes',
      'loadApprehensionItems'
    ]),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquents: 'SET_DELINQUENTS'
    }),
    clearDate () {
      this.dateOfApprehension = null
    },
    loadAlldelinquents () {
      this.loading = true
      const pageNumber = this.page - 1
      const { sortBy, sortDesc } = this.options
      // console.log(`${sortBy[0]},${sortDesc[0] === true ? 'desc' : 'asc'}`)
      this.$delinquentService
        .fetchAllTransfers(
          'deviant_acts',
          false,
          null,
          null,
          'ADMITTED,REHABILITATION,TRANSFER_INITIATED',
          pageNumber,
          this.delinquentsPerPage,
          `${
            sortBy[0] === undefined ? 'recordDate' : 'apprehension.' + sortBy[0]
          },${sortDesc[0] ? 'desc' : 'asc'}`
        )
        .then(response => {
          this.totalPages = response.totalPages
          if (response.content) {
            this.setDelinquents(response.content)
            this.totalDelinquents = response.totalElements
            this.loading = false
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
            this.loading = false
          }
        })
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
        this.birthDistricts = []
        this.birthSectors = []
        this.birthCells = []
        this.birthVillages = []
        this.editedDelinquent.placeOfBirth.province = new Location()
        this.editedDelinquent.placeOfBirth.district = new Location()
        this.editedDelinquent.placeOfBirth.sector = new Location()
        this.editedDelinquent.placeOfBirth.cell = new Location()
        this.editedDelinquent.placeOfBirth.village = new Location()
        this.editedDelinquent.apprehensionPlace.province = new Location()
        this.editedDelinquent.apprehensionPlace.district = new Location()
        this.editedDelinquent.apprehensionPlace.sector = new Location()
        this.editedDelinquent.apprehensionPlace.cell = new Location()
        this.editedDelinquent.apprehensionPlace.village = new Location()
        this.editedDelinquent.delinquentResidence.province = new Location()
        this.editedDelinquent.delinquentResidence.district = new Location()
        this.editedDelinquent.delinquentResidence.sector = new Location()
        this.editedDelinquent.delinquentResidence.cell = new Location()
        this.editedDelinquent.delinquentResidence.village = new Location()
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    loadBirthProvinces () {
      this.birthDistricts = []
      this.birthSectors = []
      this.birthCells = []
      this.birthVillages = []
      this.apprehensionDistricts = []
      this.apprehensionSectors = []
      this.apprehensionCells = []
      this.apprehensionVillages = []
      this.residenceDistricts = []
      this.residenceSectors = []
      this.residenceCells = []
      this.residenceVillages = []
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
        this.birthDistricts = []
        this.birthSectors = []
        this.birthCells = []
        this.birthVillages = []
        this.editedDelinquent.placeOfBirth = new Address()
        this.editedDelinquent.placeOfBirth.province = new Location()
        this.editedDelinquent.placeOfBirth.district = new Location()
        this.editedDelinquent.placeOfBirth.sector = new Location()
        this.editedDelinquent.placeOfBirth.cell = new Location()
        this.editedDelinquent.placeOfBirth.village = new Location()
        this.editedDelinquent.apprehensionPlace = new Address()
        this.editedDelinquent.apprehensionPlace.province = new Location()
        this.editedDelinquent.apprehensionPlace.district = new Location()
        this.editedDelinquent.apprehensionPlace.sector = new Location()
        this.editedDelinquent.apprehensionPlace.cell = new Location()
        this.editedDelinquent.apprehensionPlace.village = new Location()
        this.editedDelinquent.delinquentResidence = new Address()
        this.editedDelinquent.delinquentResidence.province = new Location()
        this.editedDelinquent.delinquentResidence.district = new Location()
        this.editedDelinquent.delinquentResidence.sector = new Location()
        this.editedDelinquent.delinquentResidence.cell = new Location()
        this.editedDelinquent.delinquentResidence.village = new Location()
      } else {
        this.reloadStoreProvinces()
      }
    },
    loadBirthDistricts (parentId) {
      this.birthSectors = []
      this.birthCells = []
      this.birthVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.birthDistricts = response
          this.editedDelinquent.placeOfBirth.sector = new Location()
          this.editedDelinquent.placeOfBirth.cell = new Location()
          this.editedDelinquent.placeOfBirth.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('District of Birth: ' + response.message)
        }
      })
    },

    loadBirthSectors (parentId) {
      this.birthCells = []
      this.birthVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.birthSectors = response
          this.editedDelinquent.placeOfBirth.cell = new Location()
          this.editedDelinquent.placeOfBirth.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Sector of Birth: ' + response.message)
        }
      })
    },

    loadBirthCells (parentId) {
      this.birthVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.birthCells = response
          this.editedDelinquent.placeOfBirth.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Cell of Birth: ' + response.message)
        }
      })
    },

    loadBirthVillages (parentId) {
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.birthVillages = response
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Village of Birth: ' + response.message)
        }
      })
    },
    loadApprehensionDistricts (parentId) {
      this.apprehensionSectors = []
      this.apprehensionCells = []
      this.apprehensionVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.apprehensionDistricts = response
          this.editedDelinquent.apprehensionPlace.sector = new Location()
          this.editedDelinquent.apprehensionPlace.cell = new Location()
          this.editedDelinquent.apprehensionPlace.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Apprehension District: ' + response.message)
        }
      })
    },

    loadApprehensionSectors (parentId) {
      this.apprehensionCells = []
      this.apprehensionVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.apprehensionSectors = response
          this.editedDelinquent.apprehensionPlace.cell = new Location()
          this.editedDelinquent.apprehensionPlace.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Apprehension Sector: ' + response.message)
        }
      })
    },

    loadApprehensionCells (parentId) {
      this.apprehensionVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.apprehensionCells = response
          this.editedDelinquent.apprehensionPlace.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Apprehension Cell: ' + response.message)
        }
      })
    },

    loadApprehensionVillages (parentId) {
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.apprehensionVillages = response
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Apprehension Village: ' + response.message)
        }
      })
    },
    loadResidenceDistricts (parentId) {
      this.residenceSectors = []
      this.residenceCells = []
      this.residenceVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.residenceDistricts = response
          this.editedDelinquent.delinquentResidence.sector = new Location()
          this.editedDelinquent.delinquentResidence.cell = new Location()
          this.editedDelinquent.delinquentResidence.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Residence District: ' + response.message)
        }
      })
    },

    loadResidenceSectors (parentId) {
      this.residenceCells = []
      this.residenceVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.residenceSectors = response
          this.editedDelinquent.delinquentResidence.cell = new Location()
          this.editedDelinquent.delinquentResidence.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Residence Sector: ' + response.message)
        }
      })
    },

    loadResidenceCells (parentId) {
      this.residenceVillages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.residenceCells = response
          this.editedDelinquent.delinquentResidence.village = new Location()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Residence Cell: ' + response.message)
        }
      })
    },

    loadResidenceVillages (parentId) {
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.residenceVillages = response
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Residence Village: ' + response.message)
        }
      })
    },
    viewDelinquent (item) {
      this.view = false
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(item.delinquent.id, 'latest_apprehension')
        .then(response => {
          if (response.id) {
            if (this.privileges.includes('READ_APPREHENSIONS')) {
              this.editedDelinquent = response
              this.retrievedApprehensionDate =
                response.apprehension.apprehensionDate
              this.fetchApprehensionSiblings()
              this.fetchResidenceSiblings()
            } else {
              this.editedDelinquent = {
                ...response,
                apprehension: new Apprehension()
              }
            }

            this.retrievedDateOfBirth = response.dateOfBirth
            this.fetchBirthSiblings()
            // this.webcamPicture = 'data:image/jpeg;base64,' + response.picture
            this.webcamPicture = response.picture
          } else {
            this.editedDelinquent.nid = null
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    editDelinquent (item) {
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(item.delinquent.id, 'latest_apprehension')
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            if (this.editedDelinquent.nid === null) this.hasId = true
            this.retrievedDateOfBirth = response.dateOfBirth
            this.dateOfApprehension = response.apprehension.apprehensionDate
            // this.retrievedApprehensionDate =
            //   response.apprehension.apprehensionDate
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            // this.webcamPicture = 'data:image/jpeg;base64,' + response.picture
            this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.selectedNida = 'NOTID'
      this.edit = false
      this.dialog = true
    },
    delinquentDialog (item) {
      this.delinquentIndex = this.delinquents.indexOf(item)
      this.delinquentItem = item
      this.deleteDialog = true
    },

    deleteDelinquent (item) {
      this.deleteDialog = false
      this.$delinquentService
        .deleteApprehension(this.delinquentItem.apprehension.id)
        .then(response => {
          if (response.status === 'success') {
            this.delinquents.splice(this.delinquentIndex, 1)
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      this.view = true
      this.recividist = false
      this.hasId = false
      this.disableSave = false
      setTimeout(() => {
        this.editedDelinquent = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1 && !this.recividist) {
          this.updateDelinquent()
        } else this.createDelinquent()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateDelinquent () {
      this.disableSave = true
      const deletedDelinquentArray = this.delinquents.splice(
        this.editedIndex,
        1
      )
      // this.$refs.container.validate()
      this.$delinquentService
        .saveDelinquent(this.editedDelinquent)
        .then(response => {
          if (response && response.delinquent && response.delinquent.id) {
            this.delinquents.splice(this.editedIndex, 0, response)
            this.disableSave = false
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.delinquents.splice(
              this.editedIndex,
              0,
              deletedDelinquentArray[0]
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
            this.disableSave = false
          }
        })
    },
    createDelinquent () {
      if (!this.$refs.form1.validate()) {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('form is not complete')
        return
      }
      this.disableSave = true
      this.editedDelinquent.dateOfBirth = this.formattedDateOfBirth
      this.editedDelinquent.apprehension.apprehensionDate = this.formattedDateOfApprehension
      if (this.editedDelinquent.id === null) {
        this.$delinquentService
          .saveDelinquent(this.editedDelinquent)
          .then(response => {
            this.disableSave = false
            if (response.delinquent.id) {
              this.delinquents.push(response)
              this.close()
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        const tobeSaved = {
          ...this.editedDelinquent.apprehension,
          delinquent: {
            id: this.editedDelinquent.id
          }
        }
        this.$delinquentService.saveApprehension(tobeSaved).then(response => {
          this.disableSave = false
          if (response.id) {
            // this.delinquents.push(response)
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      }
    },
    addNewDeviantAct (newDeviantAct) {
      this.deviantActs.push(newDeviantAct)
      this.editedDelinquent.apprehension.deviantActs.push(newDeviantAct)
    },
    addNewApprehensionItem (newApprehensionItem) {
      this.apprehensionItems.push(newApprehensionItem)
      this.editedDelinquent.apprehension.apprehensionItems.push(
        newApprehensionItem
      )
    },
    addNewIdentificationDocument (newIdentificationDocument) {
      this.identificationDocuments.push(newIdentificationDocument)
      this.editedDelinquent.apprehension.documentTypes.push(
        newIdentificationDocument
      )
    },
    saveDateOfBirth (dateOfBirth) {
      this.$refs.dateOfBirthMenu.save(dateOfBirth)
    },
    saveDateOfApprehension (dateOfApprehension) {
      this.$refs.dateOfApprehensionMenu.save(dateOfApprehension)
    },
    loadDelinquentIdentification () {
      this.$delinquentService
        .loadDelinquentIdentificationNid(this.editedDelinquent.nid)
        .then(response => {
          if (!response.status) {
            this.editedDelinquent.firstName = response.firstName
            this.editedDelinquent.lastName = response.lastName
            this.editedDelinquent.gender = response.gender
            this.editedDelinquent.maritalStatus = response.maritalStatus
            this.editedDelinquent.motherName = response.motherName
            this.editedDelinquent.fatherName = response.fatherName
            this.editedDelinquent.dateOfBirth = response.dateOfBirth
            this.retrievedDateOfBirth = this.editedDelinquent.dateOfBirth
            this.editedDelinquent.placeOfBirth = response.placeOfBirth
            this.fetchBirthSiblings()

            if (response.id) {
              this.recividist = true
              this.editedDelinquent.id = response.id
            } else this.recividist = false
            if (response.nid) {
              this.webcamPicture = response.picture
            } else {
              this.nidaPicture = 'data:image/png;base64,' + response.picture
            }
          } else {
            this.editedDelinquent.nid = null
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.recividist = false
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    resetPassportAndNid () {
      this.editedDelinquent.nid = null
      this.editedDelinquent.passport = null
    },
    resetEdit () {
      this.edit = true
      this.selectedNida = null
    },
    onCapture () {
      this.webcamPicture = this.$refs.webcam.capture()
      this.editedDelinquent.picture = this.webcamPicture
      this.nidaPicture = null
    },
    onStarted (stream) {
      // console.log('On Started Event', stream)
    },
    onStopped (stream) {
      // console.log('On Stopped Event', stream)
    },
    onStop () {
      this.$refs.webcam.stop()
      // this.webcamPicture = null
    },
    onStart () {
      this.$refs.webcam.start()
    },
    onError (error) {
      // console.log('On Error Event', error)
      this.setSnackbar(true)
      this.setColor('red')
      this.setText('On Error Event', error)
    },
    onCameras (cameras) {
      this.devices = cameras
      // console.log('On Cameras Event', cameras)
    },
    onCameraChange (deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      // console.log('On Camera Change Event', deviceId)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>

<style scoped></style>
